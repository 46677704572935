<template>
	<v-container fluid>
		<v-app-bar color="background" elevation="0" class="py-lg-2 pr-lg-3 pr-1">
			<v-btn icon rounded exact :to="{name: 'Account'}">
				<v-icon>mdi-arrow-left</v-icon>
			</v-btn>
			<v-spacer></v-spacer>
		</v-app-bar>
		<v-container>
			<v-row justify="center" align="center" class="fill-height">
				<v-col cols="12" sm="8" md="8" lg="6" xl="4">
					<v-card elevation="0" class="pa-5 bg-fade">
						<v-card-title class="text-h4">
							<v-img alt="Rewind Logo" class="shrink mr-2" contain src="../../assets/logo_small.png" transition="scale-transition" width="40"/>
							rewind
						</v-card-title>
						<v-card-subtitle class="mt-5 text-h5">
							create a linked account. you will be able to switch to this account at any time from the user menu.
						</v-card-subtitle>
						<v-card-text>
							<v-form ref="form" lazy-validation autocomplete="off">
								<v-text-field v-model="user.first_name" :rules="nameRules" label="first name" required persistent-placeholder></v-text-field>
								<v-text-field v-model="user.last_name" :rules="nameRules" label="last name" required persistent-placeholder></v-text-field>
								<v-menu v-model="dateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition" offset-y min-width="auto">
									<template v-slot:activator="{ on, attrs }">
										<v-text-field v-model="prettyDate" label="date of birth" readonly v-bind="attrs" v-on="on" hint="we only use your date of birth to calculate your memory timeline." persistent-hint persistent-placeholder></v-text-field>
									</template>
									<v-date-picker v-model="user.date_of_birth" @input="dateMenu = false" :max="getMaxEndDate"></v-date-picker>
								</v-menu>
								<v-text-field v-model="user.email" :rules="emailRules" label="email" required type="email" persistent-placeholder class="mt-5"></v-text-field>
								<v-text-field v-model="user.password" :rules="passwordRules" label="password" required type="password" persistent-placeholder></v-text-field>
								<v-text-field v-model="user.password_confirmation" :rules="passwordRules.concat(passwordConfirmationRule)" label="confirm password" required type="password" persistent-placeholder></v-text-field>
								<v-checkbox v-model="user.terms_agreed">
									<template #label>
										i agree to the rewind&nbsp;<a class="text-decoration-underline" href="https://rewind-remind.me/wp-content/uploads/2021/06/Rewind-Life-Ltd_Terms-and-Conditions.DOCX-0001.pdf" target="_blank" referrerpolicy="no-referrer">terms & conditions</a>
									</template>
								</v-checkbox>
								<v-checkbox v-model="user.marketing_opt_in" label="receive news, updates and offers from rewind"></v-checkbox>
								<v-card-actions color="transparent">
									<v-spacer></v-spacer>
									<v-btn @click="signup" rounded color="accent" :loading="loading">create account</v-btn>
								</v-card-actions>
							</v-form>
						</v-card-text>
						<v-card-actions>

						</v-card-actions>
					</v-card>
				</v-col>
			</v-row>
		</v-container>
	</v-container>
</template>

<script>
import {toast} from "@/utils/helpers";

export default {
	name: "New",
	metaInfo: {
		title: 'Create Linked Account',
	},
	computed: {
		passwordConfirmationRule() {
			return () => (this.user.password === this.user.password_confirmation) || 'Password must match'
		},
		prettyDate() {
			return this.user.date_of_birth ? new Date(this.user.date_of_birth).toLocaleDateString('en-GB') : new Date().toLocaleDateString('en-GB')
		},
	},
	data: () => ({
		loading: false,
		user: {
			first_name: '',
			last_name: '',
			email: '',
			date_of_birth: new Date().toISOString().substring(0, 10),
			password: '',
			password_confirmation: '',
		},
		emailRules: [
			v => !!v || 'E-mail is required',
			v => /.+@.+/.test(v) || 'E-mail must be valid',
		],
		passwordRules: [
			v => !!v || 'Password is required',
			v => v.length >= 8 || 'Password must be at least 8 characters',
		],
		passwordConfirmationRules: [
			v => !!v || 'Password is required',
			v => v.length >= 8 || 'Password must be at least 8 characters',
		],
		nameRules: [
			v => !!v || 'Password is required',
			v => v.length >= 2 || 'Password must be at least 2 characters',
		],
		dateMenu: false,
	}),
	methods: {
		signup() {
			if (this.$refs.form.validate()) {
				this.loading = true;
				this.$api.linked_account.create({user: this.user})
					.then(response => {
						this.$store.set('user/user', response.data.user)
						this.$store.set('user/token', response.data.token)
						this.$router.push({name: 'IntroWelcome'})
					})
					.catch(error => {
						toast.handleResponseErrors(error)
					})
					.finally(() => this.loading = false)
			}
		}
	}
}
</script>